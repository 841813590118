import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import VitalSignTypeCard from '../VitalSignTypeCard';
import { EmrProperties } from '../../../../../../types/emr.type';
import moment from 'moment';
import { apiGetVitalSignTrend } from '../../../../../../api/emr';

interface Props {
	nextStep: (newStep?: number) => void;
	pageType: 'detail-patient' | 'detail-emr';
	patientId?: string;
	emrDetail?: EmrProperties;
	setIsAvailable: (isAvailable: boolean) => void;
	onFetchComplete: () => void;
	data?: any;
	birthdate?: Date | string;
}

export default function VitalSignPediaGrowthChart(props: Props) {
	const { emrDetail, pageType, patientId } = props;
	const [pediaGrowthChart, setPediaGrowthChart] = useState<any>();
	const [date, setDate] = useState<string | undefined>();
	const [fromDate, setFromDate] = useState<string | undefined>();
	const [toDate, setToDate] = useState<string | undefined>(
		moment().endOf('month').toISOString(),
	);
	const history = useHistory();

	useEffect(() => {
		const fetchVitalSignPediaGrowthChart = async () => {
			try {
				const response = await apiGetVitalSignTrend(
					patientId || emrDetail?.patientId || '',
					'pedia_chart',
					emrDetail?.emrId,
				);
				if (response) {
					setPediaGrowthChart(response);
					props.setIsAvailable(true);
				} else {
					props.setIsAvailable(false);
				}
			} catch (error) {
				console.error('Error fetching  Pedia Growth Chart:', error);
				props.setIsAvailable(false);
			} finally {
				props.onFetchComplete();
			}
		};

		if (!emrDetail && patientId) {
			fetchVitalSignPediaGrowthChart();
		} else if (emrDetail && !patientId) {
			if (!props.data) {
				fetchVitalSignPediaGrowthChart();
			} else {
				setPediaGrowthChart(props.data);
			}
		}
	}, [emrDetail, patientId, props.data]);

	return (
		<>
			{pediaGrowthChart || emrDetail?.vitalSign?.vitalSignPediaChart ? (
				<VitalSignTypeCard
					pageType={pageType}
					data={pediaGrowthChart}
					typeTitle="Pedia Growth Chart"
					nextStep={() => props.nextStep(3)}
					birthdate={props.birthdate}
					setDate={setDate}
					setFromDate={setFromDate}
					setToDate={setToDate}
					date={date}
					fromDate={fromDate}
					toDate={toDate}
				/>
			) : undefined}
		</>
	);
}
