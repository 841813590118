// import {GeneralDoctorProperties} from '../api/helpers/generalDoctor.type';
// import {GeneralPatientProperties} from '../api/helpers/generalPatient.type';
import { DrugMedicineProperties, initialDrugMedicine } from './drugMedicine';
import { ICDProperties } from './icd.type';
import { DoctorProperties } from './doctor.type';

export type RxPrescriptionItem = {
	drugMedicineId: string;
	drugMedicineVariantId?: string;
	drugMedicineDetail: DrugMedicineProperties;
	quantity: number;
	sig: string;
};

export const initialRxPrescriptionItem = {
	drugMedicineId: '',
	drugMedicineVariantId: undefined,
	drugMedicineDetail: initialDrugMedicine,
	quantity: 0,
	sig: '',
};

export type RxPrescriptionProperties = {
	rxPrescriptionId?: string;
	emrId?: string;
	transactionConsultationId?: string;
	doctorId?: string;
	doctor?: DoctorProperties;
	patientId?: string;
	patientInRelationId?: string;
	// metaPatient?: GeneralPatientProperties;
	// metaPatientInRelation?: GeneralDoctorProperties;
	metaPatient?: any;
	metaPatientInRelation?: any;
	validUntil?: string;
	prescriptionItems?: RxPrescriptionItem[];
	isTemporary?: boolean;
	saveAsTemplate?: boolean;
	templateName?: string;
	icds?: ICDProperties[];
	diagnosis?: string;
	createdAt?: string;
};

const defaultDate = new Date();
defaultDate.setMonth(defaultDate.getMonth() + 2);
export const initialRxPrescription = {
	rxPrescriptionId: undefined,
	transactionConsultationId: undefined,
	doctorId: undefined,
	patientId: undefined,
	patientInRelationId: undefined,
	metaPatient: undefined,
	metaPatientInRelation: undefined,
	validUntil: defaultDate.toISOString(),
	prescriptionItems: [],
	saveAsTemplate: false,
};
