import { MenuOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import {
	Avatar,
	Badge,
	Button,
	Card,
	Col,
	Divider,
	Image,
	Input,
	List,
	Modal,
	Row,
	Select,
	Spin,
	Tag,
	Typography,
} from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useFetchList from '../../hooks/useFetchList';
import AppLayout from '../../layout/AppLayout';
import {
	DoctorProperties,
	SpecialistProperties,
} from '../../types/doctor.type';

const { Option } = Select;

export default function DoctorDirectoryScreen() {
	const activePath = '/app/doctor-directory';

	const { Text, Title } = Typography;
	const history = useHistory();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [telemedicine, setTelemedicine] = useState(false);
	const [inPerson, setInPerson] = useState(false);
	const [selectedItems, setSelectedItems] = useState<string[]>([]);

	const {
		isLoading,
		data,
		pagination,
		query,
		setData,
		setSearch,
		setQuery,
		fetchList,
		changePage,
		setIsLoading,
	} = useFetchList<DoctorProperties>({
		endpoint: 'doctors/find/without-pagination',
		initialQuery: {
			limit: 50000,
			kycStatus: 'APPROVED',
		},
	});

	const {
		isLoading: specialistIsLoading,
		data: specialistData,
		setData: specialistSetData,
		setSearch: specialistSetSearch,
		setQuery: specialistSetQuery,
		fetchList: specialistFetchList,
		setIsLoading: specialistSetIsLoading,
	} = useFetchList<SpecialistProperties>({
		endpoint: 'specialists',
		initialQuery: {
			limit: 10000,
		},
	});

	useEffect(() => {
		if (data) {
			console.log(
				data.filter((item) => item.schedulesInPartners?.length || 0 > 0),
			);
		}
	}, [data]);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		console.log('selected items:::', selectedItems);
		if (
			(inPerson === true && telemedicine === true) ||
			(inPerson === false && telemedicine === false)
		) {
			setQuery((query) => ({
				...query,
				kycStatus: 'APPROVED',
				filterSpecialistIds: selectedItems.join(','),
			}));
		} else if (inPerson === true && telemedicine === false) {
			setQuery((query) => ({
				...query,
				kycStatus: 'APPROVED',
				filterSpecialistIds: selectedItems.join(','),
				availableConsultationTypes: 'FACE_TO_FACE_CONSULTATION',
			}));
		} else if (inPerson === false && telemedicine === true) {
			setQuery((query) => ({
				...query,
				kycStatus: 'APPROVED',
				filterSpecialistIds: selectedItems.join(','),
				availableConsultationTypes:
					'TELEMEDICINE_CHAT_CONSULTATION,TELEMEDICINE_VIDEO_CHAT_CONSULTATION,CONSULT_NOW_CHAT_CONSULTATION,CONSULT_NOW_VIDEO_CHAT_CONSULTATION',
			}));
		}
		setIsModalOpen(false);
		fetchList();
	};

	const handleCancel = () => {
		setSelectedItems([]);
		setInPerson(false);
		setTelemedicine(false);
		setIsModalOpen(false);
	};

	const handleTagSelect = (id: string) => {
		if (selectedItems.includes(id)) {
			setSelectedItems(selectedItems.filter((item) => item !== id));
		} else {
			setSelectedItems([...selectedItems, id]);
		}
		handleOk();
	};

	const description = (specialists: string, experiences: any) => {
		return (
			<>
				<Row>
					<Text type="secondary" style={{ fontSize: 14 }}>
						{specialists}
					</Text>
				</Row>
				<Row>
					<Text type="secondary" style={{ fontSize: 14 }}>
						{experiences !== undefined
							? `${experiences} Years Experiences`
							: null}
					</Text>
				</Row>
			</>
		);
	};

	const generateSpecialisationString = (
		specialisations: SpecialistProperties[],
	) => {
		let output = '';
		for (let item of specialisations) {
			output += item.specialistName;
			output += ' - ';
		}
		return output.slice(0, output.length - 3);
	};

	const customTelemedicineButton = () => {
		if (telemedicine === false) {
			return (
				<Button
					type="ghost"
					size="large"
					block
					style={{ color: '#A5B2BD', borderColor: '#A5B2BD' }}
					onClick={() => {
						setTelemedicine(!telemedicine);
					}}
				>
					<Image
						src="/icons/filter-telemedicine.svg"
						preview={false}
						style={{ paddingRight: 5 }}
					/>
					Telemedicine
				</Button>
			);
		} else {
			return (
				<Button
					type="ghost"
					size="large"
					block
					onClick={() => {
						setTelemedicine(!telemedicine);
					}}
					style={{ color: '#D81F64', borderColor: '#D81F64' }}
				>
					<Image
						src="/icons/filter-telemedicine-chosen.svg"
						preview={false}
						style={{ paddingRight: 5 }}
					/>
					Telemedicine
				</Button>
			);
		}
	};

	const customInPersonButton = () => {
		if (inPerson === false) {
			return (
				<Button
					type="ghost"
					size="large"
					block
					style={{ color: '#A5B2BD', borderColor: '#A5B2BD' }}
					onClick={() => {
						setInPerson(!inPerson);
					}}
				>
					<Image
						src="/icons/filter-in-person.svg"
						preview={false}
						style={{ paddingRight: 5 }}
					/>
					In Person
				</Button>
			);
		} else {
			return (
				<Button
					type="ghost"
					size="large"
					block
					onClick={() => {
						setInPerson(!inPerson);
					}}
					style={{ color: '#D81F64', borderColor: '#D81F64' }}
				>
					<Image
						src="/icons/filter-in-person-chosen.svg"
						preview={false}
						style={{ paddingRight: 5 }}
					/>
					In Person
				</Button>
			);
		}
	};

	const tagRender = (props: CustomTagProps) => {
		const { label, value, closable, onClose } = props;
		const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
			event.preventDefault();
			event.stopPropagation();
		};
		return (
			<>
				<Tag
					color={value}
					onMouseDown={onPreventMouseDown}
					closable={closable}
					onClose={onClose}
					style={{
						marginRight: 3,
						backgroundColor: '#D81F64',
						color: 'white',
						position: 'relative',
						bottom: -30,
						marginTop: 10,
					}}
				>
					{label}
				</Tag>
			</>
		);
	};

	useEffect(() => {
		fetchList();
		specialistFetchList();
	}, []);

	return (
		<AppLayout activePath={activePath}>
			<Card style={{ borderRadius: 12 }}>
				<Title level={5} style={{ fontSize: 24, fontWeight: 600 }}>
					Doctor Directory
				</Title>
				<Divider />
				<Row align="middle">
					<Input
						size="middle"
						placeholder="Search by name or specialization"
						prefix={<SearchOutlined style={{ color: '#93A1B0' }} />}
						allowClear
						onChange={(e) => setSearch(e.target.value)}
						style={{ width: '92%' }}
					/>
					<Badge
						count={selectedItems.length > 0 ? selectedItems.length : 0}
						style={{ backgroundColor: '#D81F64' }}
						showZero={false}
					>
						<MenuOutlined
							style={{ color: '#929292', paddingLeft: 10, cursor: 'pointer' }}
							onClick={() => {
								setIsModalOpen(true);
							}}
						/>
					</Badge>
				</Row>
				<div
					style={{
						overflowX: 'auto',
						whiteSpace: 'nowrap',
						padding: '8px 4px',
						scrollbarWidth: 'none',
						msOverflowStyle: 'none',
					}}
				>
					<Row gutter={[8, 8]} wrap={false} style={{ flexWrap: 'nowrap' }}>
						{specialistData &&
							specialistData.map((specialist, index) =>
								index < 7 ? (
									<Tag
										key={index}
										onClick={() =>
											handleTagSelect(specialist.specialistId || '')
										}
										style={{
											cursor: 'pointer',
											background: selectedItems.includes(
												specialist.specialistId || '',
											)
												? '#ffffff'
												: '#f0f0f0',
											color: selectedItems.includes(
												specialist.specialistId || '',
											)
												? '#D81F64'
												: '#000',
											border: selectedItems.includes(
												specialist.specialistId || '',
											)
												? '1px solid #D81F64'
												: '1px solid #d9d9d9',
											padding: '5px 10px',
											fontSize: '14px',
										}}
									>
										{specialist.specialistName}
									</Tag>
								) : index === 7 ? (
									<Tag
										key="see-more"
										style={{
											display: 'inline-flex',
											alignItems: 'center',
											color: '#000',
											background: '#f0f0f0',
											border: '1px solid #d9d9d9',
											paddingLeft: 10,
											cursor: 'pointer',
										}}
										onClick={() => {
											setIsModalOpen(true);
										}}
									>
										See More
									</Tag>
								) : null,
							)}
					</Row>
				</div>

				<Spin spinning={isLoading}>
					<List
						itemLayout="horizontal"
						dataSource={data.filter(
							(item) =>
								item.schedules?.length ||
								0 > 0 ||
								item.schedulesInPartners?.length ||
								0 > 0,
						)}
						renderItem={(item) => (
							<List.Item
								onClick={() => {
									history.push('/app/doctor-directory-details', item);
								}}
							>
								<List.Item.Meta
									style={{ cursor: 'pointer' }}
									key={item.doctorId}
									avatar={
										<Avatar
											size={48}
											style={{
												backgroundImage: `url(${item.user?.profilePic})`,
												backgroundSize: 'cover',
												backgroundPosition: 'center',
												borderRadius: '50%',
											}}
										>
											{!item.user?.profilePic && <UserOutlined />}
										</Avatar>
									}
									title={
										<Text style={{ fontWeight: 600, fontSize: 16 }}>
											{`${item.user?.name} ${
												item.title === '' ? 'Not set' : `${item.title}`
											}`}
										</Text>
									}
									description={description(
										generateSpecialisationString(item.specialists!),
										item.experience,
									)}
								/>
							</List.Item>
						)}
					/>
				</Spin>
				<Modal
					open={isModalOpen}
					onOk={handleOk}
					onCancel={handleCancel}
					closable={false}
				>
					<Row justify="center">
						<Title level={5}>Filter</Title>
						<Text
							style={{
								position: 'absolute',
								right: 0,
								paddingRight: 24,
								fontWeight: 500,
								color: '#D81F64',
								cursor: 'pointer',
							}}
							onClick={() => {
								setSelectedItems([]);
								setInPerson(false);
								setTelemedicine(false);
							}}
						>
							Reset Filter
						</Text>
					</Row>
					<Row>
						<Text strong>Doctor Specialty</Text>
					</Row>
					<Row style={{ paddingTop: 10 }}>
						{!specialistIsLoading && (
							<Select
								mode="multiple"
								showArrow
								value={selectedItems}
								onChange={setSelectedItems}
								tagRender={tagRender}
								style={{ width: '100%' }}
							>
								{specialistData &&
									specialistData.map((e, index) => {
										return (
											<Option key={index} value={e.specialistId!}>
												{e.specialistName}
											</Option>
										);
									})}
							</Select>
						)}
					</Row>
					<Row>
						<Text style={{ paddingTop: 40 }} strong>
							Consultation Availability
						</Text>
					</Row>
					<Row gutter={10} style={{ paddingTop: 10 }}>
						<Col span={12}>{customTelemedicineButton()}</Col>
						<Col span={12}>{customInPersonButton()}</Col>
					</Row>
				</Modal>
			</Card>
		</AppLayout>
	);
}
