import { CertificateProps } from './certificate.type';
import { BaseResponseProps } from './config.type';
import { EducationProps } from './education.type';
import { SpecialistProps } from './specialist.type';
import { DoctorPartnerScheduleProperties } from './doctorPartnerSchedule';
import { initialUser, IUser } from './user.type';

// export interface DoctorProps {
// 	doctorId: string;
// 	title?: string;
// 	doctorName?: string;
// 	prcNumber?: string;
// 	ptrNumber?: string;
// 	tinNumber?: string;
// 	specialityCertificate?: string;
// 	startWork?: string | Date;
// 	kycStatus?: string;
// 	nameOfSignature?: string;
// 	signatureImageUrl?: string;
// 	experience?: number;
// 	createdAt?: Date;
// 	updatedAt?: Date;
// 	partners?: any[];
// 	specialists?: SpecialistProps[];
// 	certificates?: CertificateProps[];
// 	educations?: EducationProps[];
// 	otherSpecialist?: string;
// 	// partners?: PartnerDoctorProperties[];

// 	user?: IUser;
// }

export enum EKycStatus {
	WAITING_APPROVAL = 'WAITING_APPROVAL',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
	BLOCKED = 'BLOCKED',
}

export type DoctorProperties = {
	doctorId?: string;
	title: string; // S.Kom

	prcNumber?: string;
	ptrNumber?: string;
	tinNumber?: string;

	kycStatus?: EKycStatus;

	nameOfSignature?: string;
	signatureImageUrl?: string;
	specialists?: DoctorSpecialistProperties[];
	educations?: EducationProperties[];
	certificates?: CertificateProperties[];
	experience?: number;
	otherSpecialist?: string;

	user?: IUser;
	availableConsultationTypes?: AvailabilityConsultationType[];
	schedules?: DoctorPartnerScheduleProperties[];
	schedulesInPartners?: DoctorPartnerScheduleProperties[];

	createdAt?: string;
	updatedAt?: string;
};

export const initialDoctor: DoctorProperties = {
	doctorId: '',
	title: '',
	prcNumber: '',
	ptrNumber: '',
	tinNumber: '',
	kycStatus: undefined,
	nameOfSignature: '',
	signatureImageUrl: '',
	specialists: [],
	otherSpecialist: '',
	educations: [],
	certificates: [],
	experience: 0,
	createdAt: '',
	updatedAt: '',
	user: initialUser,
};

export interface FetchAllDoctorResponse extends BaseResponseProps {
	payload: {
		count: number;
		prev: string;
		next: string;
		results: DoctorProperties[];
	};
}

// export const initialDoctor: DoctorProps = {
// 	doctorId: "",
// 	title: "",
// 	doctorName: "",
// 	prcNumber: "",
// 	ptrNumber: "",
// 	tinNumber: "",
// 	specialityCertificate: "",
// 	startWork: "",
// 	kycStatus: "",
// 	nameOfSignature: "",
// 	signatureImageUrl: "",
// 	experience: 0,
// 	specialists: [],
// 	certificates: [],
// 	educations: [],
// 	partners: [],
// };

export const initialDoctorFetchAll: FetchAllDoctorResponse = {
	code: '',
	message: '',
	payload: {
		count: 0,
		prev: '',
		next: '',
		results: [initialDoctor],
	},
};

export interface UploadCertificateResponse {
	name: string;
	certificateId: string;
	doctorId: string;
	url: string;
	createdAt?: string;
	updatedAt?: string;
}

export interface UploadSignatureResponse {
	signatureImageUrl: string;
}

export type SpecialistProperties = {
	specialistId?: string;
	specialistName: string;
	icon?: string;
	specialistAliasName?: string | null;
	// active?: boolean;
	selected?: boolean;
	createdAt?: string;
	updatedAt?: string;
	specialists?: string;

	// isNoSpecialization?: boolean;
	// childrens?: SpecialistProperties[];
};

export type DoctorSpecialistProperties = {
	id?: string;
	specialistId: string;
	specialistName: string;
	subSpecialistId?: string;
	subSpecialistName?: string;
	doctorId?: string;
	createdAt?: string;
	updatedAt?: string;
};

export type EducationProperties = {
	educationId: string;
	doctorId: string;
	stage?: string;
	institutionName?: string;
	startYear?: string;
	endYear?: string;
	createdAt: Date;
	updatedAt: Date;
};

export type CertificateProperties = {
	certificateId: string;
	doctorId: string;
	url?: string;
	name?: string;
	createdAt: Date;
	updatedAt: Date;
};

export enum AvailabilityConsultationType {
	TELEMEDICINE_VIDEO_CHAT_CONSULTATION = 'TELEMEDICINE_VIDEO_CHAT_CONSULTATION',
	TELEMEDICINE_CHAT_CONSULTATION = 'TELEMEDICINE_CHAT_CONSULTATION',
	FACE_TO_FACE_CONSULTATION = 'FACE_TO_FACE_CONSULTATION',
}
