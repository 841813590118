import { SearchOutlined } from '@ant-design/icons';
import {
	Card,
	Col,
	Divider,
	Input,
	Row,
	Spin,
	Tabs,
	Tag,
	Typography,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	iconAppointment as IconAppointmentColorize,
	iconEmptySecretary as IconEmpty,
	iconMedService as IconMedServiceColorize,
	iconPersonalEvent as IconPersonalEventColorize,
	iconPersonalEventBlocked as IconPersonalEventBlockedColorize,
	iconTelemedicine as IconTelemedicineColorize,
	iconWarningCircle as IconConsultNowColorize,
	iconWebinar as IconWebinarColorize,
	iconEMR as IconEMR,
} from '../../../assets/icons';
import EmptyList from '../../../components/EmptyList';
import { getScheduleType } from '../../../constants';
import { useAuth } from '../../../context/auth.context';
import { getName } from '../../../helpers/name';
import useFetchList from '../../../hooks/useFetchList';
import AppLayout from '../../../layout/AppLayout';
import { EScheduleTypes } from '../../../types/schedule.type';
import {
	ETransactionStatusType,
	TransactionConsultationProperties,
} from '../../../types/transactionConsultation.type';
import { TransactionHealthServiceProps } from '../../../types/transactionHealthService.type';
import ListRxHistory from '../../../components/Webapp/RxAndLabRequest/RxHistory/ListRxHistory';
import { RxPrescriptionProperties } from '../../../types/rxPrescription.type';
import ListLabRequestHistory from '../../../components/Webapp/RxAndLabRequest/LabRequestHistory/ListLabRequestHistory';
import { LabRequestProperties } from '../../../types/labRequest.type';
import DoctorDocumentList from '../../../components/Webapp/DoctorDocument/DoctorDocumentHistory/DoctorDocumentList';
import { EmrProperties } from '../../../types/emr.type';

const activePath = '/app/history';
const { Title, Text } = Typography;

type HistoryItem = {
	id: string;
	title: string;
	type?: EScheduleTypes | string;
	doctor?: string;
	date: string;
	createdAt?: Date | string;
	status?: string;
};

type EmrItem = {
	id: string;
};

export type HistoryGroupItem = {
	title: string;
	date: string;
	data: HistoryItem[];
};

export default function HistoryScreen() {
	const { user } = useAuth();
	const history = useHistory();
	const [activeTab, setActiveTab] = useState<string>('0');
	const [searchOther, setSearchOther] = useState('');
	const [selectedPrescription, setSelectedPrescription] =
		useState<RxPrescriptionProperties>();

	const [selectedLabRequest, setSelectedLabRequest] =
		useState<LabRequestProperties>();

	function nextStep() {}

	useEffect(() => {
		if (selectedPrescription?.rxPrescriptionId) {
			history.push({
				pathname: 'rx-and-lab-request/rx-history',
				search: `?rxPrescriptionId=${selectedPrescription.rxPrescriptionId}`,
			});
		}
	}, [selectedPrescription]);

	useEffect(() => {
		if (selectedLabRequest?.labRequestId) {
			history.push(
				`rx-and-lab-request/lab-request-history?labRequestId=${selectedLabRequest?.labRequestId}`,
			);
		}
	}, [selectedLabRequest]);

	function toDetail(docId: string) {
		history.push(`/app/doctor-documents/history/${docId}`);
	}

	// Fetch API
	const {
		data: consultationListDoctor,
		isLoading: isLoadingConsultationDoctor,
		pagination: paginationConsultationDoctor,
		search: searchConsultationDoctor,
		changePage: changePageConsultationDoctor,
		setSearch: setSearchConsultationDoctor,
		fetchList: fetchConsultationListDoctor,
	} = useFetchList<TransactionConsultationProperties>({
		endpoint: '/transaction-consults',
		limit: 100,
		initialQuery: {
			doctorId: user?.userId,
			filterTransactionStatuses: [
				ETransactionStatusType.WAITING_APPROVAL,
				ETransactionStatusType.APPROVED,
				ETransactionStatusType.REJECTED,
				ETransactionStatusType.REJECTED_CHANGE_DOCTOR,
				ETransactionStatusType.REJECTED_REFUND,
				ETransactionStatusType.COMPLETED,
				ETransactionStatusType.CANCELED_BY_DOCTOR_OR_SECRETARY,
				ETransactionStatusType.CANCELED_BY_USER,
				ETransactionStatusType.REJECTED_CHANGE_SCHEDULE,
			],
		},
		pushData: true,
		primaryKey: 'transactionConsultationId',
	});

	const {
		data: emrList,
		isLoading: isLoadingEmr,
		pagination: paginationEmr,
		changePage: changePageEmr,
		setSearch: setSearchEmr,
		fetchList: fetchEmrList,
	} = useFetchList<EmrProperties>({
		endpoint: '/emr',
		limit: 100,
		initialQuery: {
			doctorId: user?.userId,
		},
		pushData: true,
		primaryKey: 'emrId',
	});

	const {
		data: healthServList,
		isLoading: isLoadingHealthServ,
		pagination: paginationHealthServ,
		changePage: changePageHealthServ,
		setSearch: setSearchHealthServ,
		fetchList: fetchHealthServList,
	} = useFetchList<TransactionHealthServiceProps>({
		endpoint: '/transaction-health-services',
		limit: 100,
		initialQuery: {
			doctorId: user?.userId,
		},
		pushData: true,
		primaryKey: 'transactionHealthServiceId',
	});

	let schedulesTelemed: HistoryGroupItem[] = [];
	let schedulesHealthServ: HistoryGroupItem[] = [];
	let schedulesEmr: HistoryGroupItem[] = [];

	if (activeTab === '0') {
		const mappedData: HistoryItem[] = (consultationListDoctor || [])
			.filter(
				(item) =>
					item.transactionStatus !==
					ETransactionStatusType.CANCELED_BY_DOCTOR_OR_SECRETARY,
			)
			.map((item) => ({
				id: item.transactionConsultationId || '-',
				title: getName(item.metaPatient) || '-',
				type: getScheduleType(item),
				date: moment(item.createdAt).format('YYYY-MM-DD'),
				createdAt: item.createdAt,
				status: item.transactionStatus,
			}));

		schedulesTelemed = groupByDate(mappedData);
		// console.log('Mapped Data', schedulesTelemed);
	}
	if (activeTab === '1') {
		const mappedData: HistoryItem[] = (emrList || []).map((item) => ({
			id: item.emrId ?? '-',
			title: getName(item.patient) || '-',
			doctor: item.doctor.name || '-',
			type: '',
			date: moment(item.createdAt).format('YYYY-MM-DD'),
			createdAt: item.createdAt || new Date(),
			status: item.status,
		}));

		schedulesEmr = groupByDate(mappedData);
	}
	if (activeTab === '2') {
		const mappedData: HistoryItem[] = (healthServList || []).map((item) => ({
			id: item.transactionHealthServiceId || '',
			title:
				item.transactionHealthServiceItems
					.map((el) => el.metaHealthService?.healthServiceName)
					.join(', ') || '-',
			type: getScheduleType(item),
			date: moment(item.createdAt).format('YYYY-MM-DD'),
			createdAt: item.createdAt,
			status: item.transactionStatus,
		}));

		schedulesHealthServ = groupByDate(mappedData);
	}

	function groupByDate(data: HistoryItem[]) {
		return data.reduce((acc: HistoryGroupItem[], curr: HistoryItem) => {
			const findIndex = acc.findIndex((item) => item.date === curr.date);
			if (findIndex > -1) {
				acc[findIndex].data.push(curr);
			} else {
				acc.push({
					title:
						(moment().isSame(curr.date, 'day') ? 'Today, ' : '') +
						moment(curr.date).format('MMM DD, YYYY'),
					date: curr.date,
					data: [curr],
				});
			}
			return acc;
		}, []);
	}

	function capitalizeAndSplit(str?: string): string {
		if (!str) return '';
		let temp = str.split('-');
		let output = '';
		for (let element of temp) {
			output += element.charAt(0).toUpperCase();
			output += element.substring(1);
			output += ' ';
		}
		return output.trim();
	}

	const date = (date: string) => {
		return (
			<Row
				style={{
					width: '100%',
					marginTop: 12,
					marginBottom: 12,
				}}
			>
				<Text
					style={{
						fontWeight: 600,
						fontSize: 16,
					}}
				>
					{date}
				</Text>
			</Row>
		);
	};

	const tag = (type: ETransactionStatusType) => {
		let temp = type;
		let status = '';
		let color = '';

		if (temp.valueOf() === ETransactionStatusType.WAITING_PAYMENT) {
			status = 'Waiting Payment';
			color = 'orange';
		} else if (temp.valueOf() === ETransactionStatusType.WAITING_APPROVAL) {
			status = 'Waiting Approval';
			color = 'orange';
		} else if (temp.valueOf() === ETransactionStatusType.APPROVED) {
			status = 'Approved';
			color = 'green';
		} else if (temp.valueOf() === ETransactionStatusType.REJECTED) {
			status = 'Rejected';
			color = 'red';
		} else if (
			temp.valueOf() === ETransactionStatusType.REJECTED_CHANGE_DOCTOR
		) {
			status = 'Rejected (Change Doctor)';
			color = 'red';
		} else if (temp.valueOf() === ETransactionStatusType.REJECTED_REFUND) {
			status = 'Rejected (Refund)';
			color = 'red';
		} else if (temp.valueOf() === ETransactionStatusType.CANCELED_BY_SYSTEM) {
			status = 'Expired';
			color = 'red';
		} else if (temp.valueOf() === ETransactionStatusType.EXPIRED) {
			status = 'Expired';
			color = 'red';
		} else if (temp.valueOf() === ETransactionStatusType.CANCELED_BY_USER) {
			status = 'Cancelled';
			color = 'red';
		} else if (temp.valueOf() === ETransactionStatusType.COMPLETED) {
			status = 'Completed';
			color = 'green';
		} else if (
			temp.valueOf() === ETransactionStatusType.REJECTED_CHANGE_SCHEDULE
		) {
			status = 'Reschedule';
			color = 'orange';
		} else if (temp.valueOf() === 'draft') {
			status = 'Draft';
			color = 'orange';
		} else if (temp.valueOf() === 'completed') {
			status = 'Completed';
			color = 'green';
		}

		if (color === 'green') {
			return (
				<Tag
					style={{
						color: '#268E6C',
						backgroundColor: '#D7F4EB',
						border: '1px solid #268E6C',
						marginRight: 0,
					}}
				>
					{status}
				</Tag>
			);
		} else if (color === 'orange') {
			return (
				<Tag
					style={{
						color: '#DA7B11',
						backgroundColor: '#FCF1E3',
						border: '1px solid #DA7B11',
						marginRight: 0,
					}}
				>
					{status}
				</Tag>
			);
		} else if (color === 'red') {
			return (
				<Tag
					style={{
						color: '#D7373F',
						backgroundColor: '#F6D5D6',
						border: '1px solid #D7373F',
						marginRight: 0,
					}}
				>
					{status}
				</Tag>
			);
		}
	};

	const icon = (scheduleType: EScheduleTypes) => {
		let icon: any = {};
		let backgroundColor: string = '';

		if (
			scheduleType.valueOf() === EScheduleTypes.telemedicine ||
			scheduleType.valueOf() === EScheduleTypes['telemedicine-personal']
		) {
			icon = <IconTelemedicineColorize width={11} />;
			backgroundColor = '#D81F64';
		} else if (
			scheduleType.valueOf() === EScheduleTypes.appointment ||
			scheduleType.valueOf() === EScheduleTypes['appointment-personal']
		) {
			icon = <IconAppointmentColorize width={11} />;
			backgroundColor = '#2B9CDC';
		} else if (scheduleType.valueOf() === EScheduleTypes['personal-event']) {
			icon = <IconPersonalEventColorize width={11} />;
			backgroundColor = '#03CEA4';
		} else if (
			scheduleType.valueOf() === EScheduleTypes['personal-event-blocked']
		) {
			icon = <IconPersonalEventBlockedColorize width={11} />;
			backgroundColor = '#DCDCDC';
		} else if (scheduleType.valueOf() === EScheduleTypes['medical-service']) {
			icon = <IconMedServiceColorize width={11} />;
			backgroundColor = '#F26B29';
		} else if (scheduleType.valueOf() === EScheduleTypes['consult-now']) {
			icon = <IconConsultNowColorize width={11} />;
			backgroundColor = '#E5AB0E';
		} else if (scheduleType.valueOf() === EScheduleTypes.webinar) {
			icon = <IconWebinarColorize width={11} />;
			backgroundColor = '#825DD1';
		} else if (scheduleType.valueOf() === '') {
			icon = <IconEMR />;
		}
		return (
			<>
				<div
					style={{
						width: 24,
						height: 24,
						backgroundColor: backgroundColor,
						borderRadius: '50%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						marginRight: 6,
					}}
				>
					{icon}
				</div>
			</>
		);
	};

	const itemConsultation = (item: HistoryItem) => {
		let transactionConsultationId = item.id;
		let patientName = item.title;
		let setting = capitalizeAndSplit(item.type as string);
		let startTime = moment(item.createdAt).format('HH:mm');
		let status = item.status as ETransactionStatusType;
		let iconType = item.type as EScheduleTypes;
		return (
			<>
				<Row
					style={{ width: '100%', cursor: 'pointer' }}
					onClick={() => {
						if (iconType && transactionConsultationId) {
							history.push('schedule/details', {
								type: iconType,
								id: transactionConsultationId,
								from: activePath,
							});
						}
					}}
				>
					<Col>{icon(iconType)}</Col>
					<Col style={{ marginLeft: 12 }}>
						<Row>
							<Text style={{ fontSize: 16, fontWeight: 600 }}>
								{patientName}
							</Text>
						</Row>
						<Row>
							<Text type="secondary" style={{ fontSize: 14 }}>
								{setting}
							</Text>
						</Row>
					</Col>
					<Col
						style={{
							position: 'absolute',
							right: 0,
						}}
					>
						<Row justify="end" style={{ width: '100%' }}>
							{startTime !== '' && (
								<Text style={{ fontSize: 14 }}>{startTime}</Text>
							)}
						</Row>
						<Row justify="end" style={{ width: '100%' }}>
							{tag(status)}
						</Row>
					</Col>
					<Divider style={{ marginTop: 12, marginBottom: 12 }} />
				</Row>
			</>
		);
	};

	const itemEMR = (item: HistoryItem) => {
		let transactionEMRId = item.id;
		let patientName = item.title;
		let doctorName = item.doctor;
		let startTime = moment(item.createdAt).format('HH:mm');
		let status = item.status as ETransactionStatusType;
		let iconType = item.type as EScheduleTypes;
		return (
			<>
				<Row
					style={{
						width: '100%',
						cursor: 'pointer',
						display: 'flex',
						justifyContent: 'space-between',
					}}
					onClick={() => {
						if (transactionEMRId) {
							const queryParams = new URLSearchParams({
								emrId: transactionEMRId,
								tab: '1',
							}).toString();
							history.push(`emr/detail?${queryParams}`);
						}
					}}
				>
					<Col
						style={{
							display: 'flex',
							alignItems: 'center',
							flexBasis: '40%',
						}}
					>
						<Col>{icon(iconType)}</Col>
						<Col style={{ marginLeft: 12 }}>
							<Row>
								<Text style={{ fontSize: 16, fontWeight: 600 }}>
									{patientName}
								</Text>
							</Row>
							<Row>
								<Text type="secondary" style={{ fontSize: 14 }}>
									EMR
								</Text>
							</Row>
						</Col>
					</Col>
					<Col
						style={{
							flexBasis: '40%',
						}}
					>
						<Row>
							<Text type="secondary" style={{ fontSize: 14 }}>
								By
							</Text>
						</Row>
						<Row>
							<Text style={{ fontSize: 16, fontWeight: 600 }}>
								{doctorName}
							</Text>
						</Row>
					</Col>
					<Col
						style={{
							flexBasis: '20%',
						}}
					>
						<Row justify="end" style={{ width: '100%' }}>
							{startTime !== '' && (
								<Text style={{ fontSize: 14 }}>{startTime}</Text>
							)}
						</Row>
						<Row justify="end" style={{ width: '100%' }}>
							{tag(status)}
						</Row>
					</Col>
					<Divider style={{ marginTop: 12, marginBottom: 12 }} />
				</Row>
			</>
		);
	};

	const list = (historyGroups: HistoryGroupItem[]) => {
		let output: any = [];
		for (let historyGroup of historyGroups) {
			output.push(date(historyGroup.title));
			for (let historyItem of historyGroup.data) {
				if (historyItem.doctor) {
					output.push(itemEMR(historyItem));
				} else {
					output.push(itemConsultation(historyItem));
				}
			}
		}
		return output;
	};

	// Tab Item Children
	const consultation = () => {
		if (!isLoadingConsultationDoctor) {
			if (list(schedulesTelemed)?.length === 0) {
				return <EmptyList icon={<IconEmpty />} title="No Consultation Yet" />;
			}
			return list(schedulesTelemed);
		} else {
			return <Spin spinning={isLoadingConsultationDoctor} />;
		}
	};

	const emr = () => {
		if (!isLoadingEmr) {
			if (list(schedulesEmr)?.length === 0) {
				return <EmptyList icon={<IconEmpty />} title="No EMR Yet" />;
			}
			return list(schedulesEmr);
		} else {
			return <Spin spinning={isLoadingEmr} />;
		}
	};

	const medicalService = () => {
		if (!isLoadingHealthServ) {
			if (list(schedulesHealthServ)?.length === 0) {
				return (
					<EmptyList icon={<IconEmpty />} title="No Medical Service Yet" />
				);
			}
			return list(schedulesHealthServ);
		} else {
			return <Spin spinning={isLoadingHealthServ} />;
		}
	};

	// Tab Items
	const tabItems = [
		{
			label: 'Consultation',
			key: '0',
			children: [consultation()],
		},
		{
			label: 'EMR',
			key: '1',
			children: [emr()],
		},
		{
			label: 'Medical Service',
			key: '2',
			children: medicalService(),
		},
		{
			label: 'Rx Prescription',
			key: '3',
			children: ListRxHistory({
				setSelectedPrescription,
				nextStep,
				disableSearchBar: true,
				search: searchOther,
			}),
		},
		{
			label: 'Lab Request',
			key: '4',
			children: ListLabRequestHistory({
				setSelectedLabRequest,
				nextStep,
				disableSearchBar: true,
				search: searchOther,
			}),
		},
		{
			label: 'Doctor Document',
			key: '5',
			children: DoctorDocumentList({
				toDetail,
				disableSearchBar: true,
				search: searchOther,
			}),
		},
	];

	return (
		<AppLayout activePath={activePath}>
			<Card>
				<Title level={5}>History</Title>
				<Divider />
				<Input
					size="middle"
					placeholder="Search"
					prefix={<SearchOutlined style={{ color: '#93A1B0' }} />}
					allowClear
					onChange={(e) => {
						if (activeTab === '0') {
							setSearchConsultationDoctor(e.target.value);
							console.log('tab 1, e.target.value::', e.target.value);
						} else if (activeTab === '2') {
							setSearchHealthServ(e.target.value);
							console.log('tab 3, e.target.value::', e.target.value);
						} else {
							setSearchOther(e.target.value);
						}
					}}
					style={{ width: '92%' }}
				/>
				<Tabs
					defaultActiveKey="0"
					centered
					items={tabItems}
					onChange={(e) => {
						setActiveTab(e);
					}}
				/>
			</Card>
		</AppLayout>
	);
}
