import { useHistory } from 'react-router-dom';
import { LabRequestProperties } from '../../../../../types/labRequest.type';
import { useEffect, useState } from 'react';
import VitalSignBmi from './VitalSignType/VitalSignBmi';
import VitalSignHeartRate from './VitalSignType/VitalSignHeartRate';
import VitalSignRespiratoryRate from './VitalSignType/VitalSignRespiratoryRate';
import VitalSignSpo2 from './VitalSignType/VitalSignSpo2';
import VitalSignTemperature from './VitalSignType/VitalSignTemperature';
import VitalSignBloodSugar from './VitalSignType/VitalSignBloodSugar';
import VitalSignBloodPressure from './VitalSignType/VitalSignBloodPressure';
import VitalSignPediaGrowthChart from './VitalSignType/VitalSignPediaGrowthChart';
import Spinner from '../../../../Spinner';
import { theme } from '../../../../../assets/theme';
import { EmrProperties } from '../../../../../types/emr.type';
import { apiGetEmr, apiGetVitalSignTrend } from '../../../../../api/emr';
import EmptyList from '../../../../EmptyList';
import { Info } from 'react-feather';

interface Props {
	labRequest: LabRequestProperties;
	nextStep?: (newStep?: number) => void;
	pageType: 'detail-patient' | 'detail-emr';
	patientId?: string;
	emrId?: string;
	patient?: EmrProperties;
}

export default function VitalSignEmrDetail(props: Props) {
	const { pageType, patientId, emrId } = props;
	const [emrDetail, setEmrDetail] = useState<EmrProperties>();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(true);
	const [vitalSign, setVitalSign] = useState<any>();

	const [availability, setAvailability] = useState<Record<string, boolean>>({
		bmi: true,
		pediaGrowthChart: true,
		heartRate: true,
		bloodSugar: true,
		respiratoryRate: true,
		temperature: true,
		bloodPressure: true,
		spo2: true,
	});
	const vitalSignComponents = [
		{
			component: VitalSignBmi,
			key: 'bmi',
			vitalSign: vitalSign?.bmi,
		},
		{
			component: VitalSignPediaGrowthChart,
			key: 'pediaGrowthChart',
			vitalSign: vitalSign?.pediaChart,
			birthdate:
				props.patient?.patient?.birthdate ||
				props.patient?.patientInRelation?.birthdate,
		},
		{
			component: VitalSignHeartRate,
			key: 'heartRate',
			vitalSign: vitalSign?.heartRate,
		},
		{
			component: VitalSignRespiratoryRate,
			key: 'respiratoryRate',
			vitalSign: vitalSign?.respiratoryRate,
		},
		{
			component: VitalSignSpo2,
			key: 'spo2',
			vitalSign: vitalSign?.spO2,
		},
		{
			component: VitalSignTemperature,
			key: 'temperature',
			vitalSign: vitalSign?.temperature,
		},
		{
			component: VitalSignBloodPressure,
			key: 'bloodPressure',
			vitalSign: vitalSign?.bloodPressure,
		},
		{
			component: VitalSignBloodSugar,
			key: 'bloodSugar',
			vitalSign: vitalSign?.bloodSugar,
		},
	];

	const totalComponents = vitalSignComponents.length;
	const [completedFetches, setCompletedFetches] = useState(0);

	const handleChildFetchComplete = () => {
		setCompletedFetches((prev) => prev + 1);
	};

	useEffect(() => {
		if (completedFetches === totalComponents) {
			setIsLoading(false);
		}
	}, [completedFetches, totalComponents]);

	useEffect(() => {
		if (emrId && !patientId) {
			const fetchVitalSign = async () => {
				try {
					const response = await apiGetEmr(emrId);
					if (response) {
						setEmrDetail(response);
						const vitalSignResponse = await apiGetVitalSignTrend(
							response.patientId,
							'all',
							response.emrId,
						);
						setVitalSign(vitalSignResponse);
					}
				} catch (error) {
					console.error('Error fetching EMR Detail:', error);
				}
			};

			fetchVitalSign();
		}
	}, [emrId, patientId]);

	return (
		<>
			{isLoading && (
				<div className="py-4 mx-auto w-fit">
					<Spinner size={25} color={theme.colors.primary} />
				</div>
			)}
			<div className={`flex-1 flex flex-col ${isLoading ? 'hidden' : ''}`}>
				{vitalSignComponents.map(
					({ component: Component, key, vitalSign, ...extraProps }) => (
						<Component
							key={key}
							patientId={patientId}
							nextStep={props.nextStep || (() => {})}
							pageType={pageType}
							emrDetail={emrDetail}
							setIsAvailable={(isAvailable: boolean) =>
								setAvailability((prev) => ({ ...prev, [key]: isAvailable }))
							}
							onFetchComplete={handleChildFetchComplete}
							data={vitalSign}
							{...extraProps}
						/>
					),
				)}
			</div>

			{Object.values(availability).every((isAvailable) => !isAvailable) &&
				(pageType === 'detail-emr' ? (
					<div className="px-3 py-6">
						<EmptyList
							icon={<Info color={theme.colors.black50} />}
							title="Data Empty"
							description="No Vital Signs data available for this patient."
						/>
					</div>
				) : (
					<EmptyList
						icon={<Info color={theme.colors.black50} />}
						title="Data Empty"
						description="You have not added this data yet"
					/>
				))}
		</>
	);
}
